import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

const NavigationMenuMobile = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuSx = { marginLeft: 5, marginRight: 5, color: "black" };

  return (
    <div>
      <Button onClick={() => navigate("/")} variant="text" sx={menuSx}>
        HOME
      </Button>

      <Button onClick={handleClick} variant="text" sx={menuSx}>
        MORE
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {["MEDIA", "RECIPES", "ABOUT"].map(
          (link, i) => (
            <MenuItem
              key={i}
              onClick={() => navigate("/" + link.toLocaleLowerCase())}
            >
              {link}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default NavigationMenuMobile;
