import * as React from "react";
import BrushIconOutlined from "@mui/icons-material/Brush";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { getRandomColor } from "./common.text";
import { TimelineTypeValues } from "./timeline/timeline.slice.enum";

export const getAvatarForType = (type: number, size: "small" | "inherit" | "large" | "medium" | undefined, overrideColor: string | null = null) => {
    switch (type) {
      case TimelineTypeValues.Art:
        return (
          <BrushIconOutlined
            fontSize={size}
            sx={{ color: overrideColor ? overrideColor : getRandomColor() }}
          />
        );
      case TimelineTypeValues.Photo:
        return (
          <AddAPhotoIcon fontSize={size} sx={{ color: overrideColor ? overrideColor : getRandomColor() }} />
        );
      case TimelineTypeValues.Video:
        return (
          <AddAPhotoIcon fontSize={size} sx={{ color: overrideColor ? overrideColor : getRandomColor() }} />
        );
      default:
        throw new Error("No type mapping");
    }
  };