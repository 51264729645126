import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import accountSlice from "../app/account/account.slice";
import recipeSlice from "../app/recipe/recipe.slice";
import tagsSlice from "../app/tags/tags.slice";
import timelineSlice from "../app/timeline/timeline.slice";

const store = configureStore({
  reducer: {
    timeline: timelineSlice,
    account: accountSlice,  
    tags: tagsSlice,
    recipes: recipeSlice
  }
});

setupListeners(store.dispatch)

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const dispatchFromStore = store.dispatch;

export const getState = () => store.getState();

 