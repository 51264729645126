import React from "react";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Typography, useMediaQuery } from "@mui/material";

const WelcomeName = () => {
  const isDesktop = useMediaQuery("(min-width:800px)");
  const { accounts } = useMsal();
  const [name, setName] = useState<null | string>();
  const { instance } = useMsal();

  const logout = () => {
    instance.logoutRedirect();
  };

  const fontSize = isDesktop ? "h6" : "subtitle2";

  useEffect(() => {
    if (accounts && accounts.length > 0 && accounts[0] && accounts[0].name) {
      setName(accounts[0].name.split(" ")[0]);
    } else {
      setName(null);
    }
  }, [accounts]);

  if (name) {
    return (
      <Typography sx={{color: 'purple'}} variant={fontSize}>
        {isDesktop && <span> Hello, {name}!</span>}{" "}
        <span onClick={() => logout()}>💜</span>
      </Typography>
    );
  }

  return null;
};

export default WelcomeName;
