import { IMedia, ITimelineSummaryResponse } from "./timeline.slice.types";

export interface ITimelineState {
    timelineSummary: ITimelineSummaryResponse;
    mediaDetail: IMedia[];
    selectedMediaDetail: IMedia | undefined;
  }

  export const initialState: ITimelineState = {
    timelineSummary: {
      timelines: [],
      limit: 1,
      pages: 1,
      currentPage: 1,
    },
    mediaDetail: [],
    selectedMediaDetail: undefined,
  };