import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRecipe, IRecipeDirectory } from "./recipe.slice.types";
import httpCommon from "../common.http";
import { getAuthToken } from "../common.auth";
import { dispatchFromStore } from "../../state/store";
import { setNoAuthAction } from "../account/account.slice";

export const getRecipeDirectoryThunk = createAsyncThunk(
  `${name}/recipeDirectory`,
  async (): Promise<IRecipeDirectory[]> => {
    const token = await getAuthToken();

    const res = await httpCommon.get<IRecipeDirectory[]>(`/recipe/directory`, {
      headers: { Authorization: `bearer ${token}` },
    });

    if (res.status === 403) {
      dispatchFromStore(setNoAuthAction());
    }

    return res.data;
  }
);

export const getRecipeThunk = createAsyncThunk(
  `${name}/recipe`,
  async (id: string): Promise<IRecipe> => {
    const token = await getAuthToken();

    const res = await httpCommon.get<IRecipe>(`/recipe/${id}`, {
      headers: { Authorization: `bearer ${token}` },
    });

    return res.data;
  }
);
