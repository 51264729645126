import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID || 'bad config',
        authority: process.env.REACT_APP_AZURE_B2C_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITIES || 'bad config'],
        redirectUri: process.env.REACT_APP_AZURE_B2C_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_AZURE_B2C_POST_lOGOUT_REDIRECT_URI
    }
};

export const loginRequest = {
    scopes: [process.env.REACT_APP_AZURE_B2C_API_READ_SCOPE || 'bad config']
};
