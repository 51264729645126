import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import AuthenticatedRouter from "../router/AuthenticatedRouter";
import { PageLayout } from "./PageLayout";

const AppAuthenticated = () => (
  <AuthenticatedTemplate>
    <PageLayout>
      <CssBaseline />
      <AuthenticatedRouter />
    </PageLayout>
  </AuthenticatedTemplate>
);

export default AppAuthenticated;
