import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useAppDispatch } from "../../common.app.hooks";
import { getMonthTextFromMonth } from "../../common.text";
import { setMonthAction } from "../tags.slice";

interface IProps {
  color: string
}

const TagMonthPicker = (props: IProps) => {
  const dispatch = useAppDispatch();
  
  const month = useSelector<RootState, number>(
    (state) => state.tags.month
  );

  const color = { color: month > 0 ? props.color : 'black' };

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setMonthAction(Number(event.target.value)));
  };

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((m) => (
    <MenuItem key={m} value={m}>
      {getMonthTextFromMonth(m)}
    </MenuItem>
  ));

  return (
    <>
      <InputLabel sx={{color}} id="month-label">Month</InputLabel>

      <Select
        sx={{color}}
        labelId="month-label"
        value={month?.toString()}
        label="Month"
        onChange={handleChange}
      >
        <MenuItem value="0">
          <em>All</em>
        </MenuItem>
        {months}
      </Select>
    </>
  );
};

export default TagMonthPicker;
