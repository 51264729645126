export enum TimelineTypeValues {
    // eslint-disable-next-line no-unused-vars
    Photo = 1,
    // eslint-disable-next-line no-unused-vars
    Art = 2,
    // eslint-disable-next-line no-unused-vars
    Video = 3,
    // eslint-disable-next-line no-unused-vars
    Recipe = 4,
  }
  
  export type TimelineType =
    | TimelineTypeValues.Art
    | TimelineTypeValues.Photo
    | TimelineTypeValues.Video
    | TimelineTypeValues.Recipe;