import {
    Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useAppDispatch } from "../../common.app.hooks";
import { toggleAppyTagThunk } from "../../timeline/timeline.slice.thunks";
import { IMedia } from "../../timeline/timeline.slice.types";
import { toggleTagsAddDialog } from "../tags.slice";
import { ITag } from "../tags.slice.types";

interface IProps {
  id: string;
}

const TagsAdd = (props: IProps) => {
  const dispatch = useAppDispatch();

  const tags = useSelector<RootState, ITag[]>((state) => state.tags.tags);

  const media = useSelector<RootState, IMedia>(
    (state) =>
      state.timeline.mediaDetail.filter((f) => f.id === props.id)[0]
  );

  const showTagsAddDialog = useSelector<RootState, boolean>(
    (state) => state.tags.showTagsAddDialog
  );

  const chips = tags.map((m, i) => (
    <Chip
      onClick={() => dispatch(toggleAppyTagThunk({tag: m.tag, id: props.id}))}
      key={i}
      label={m.tag}
      sx={{
        background: media.tags?.includes(m.tag) ? "pink" : "grey",
        color: "white",
      }}
    />
  ));

  return (
    <Dialog open={showTagsAddDialog}>
      <DialogTitle>Tags</DialogTitle>
      <DialogContent>{chips}</DialogContent>
      <DialogActions>
          <Button onClick={() => dispatch(toggleTagsAddDialog())}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagsAdd;
