import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimelineTypeValues } from "../timeline/timeline.slice.enum";
import { initialState, ITagState } from "./tags.slice.state";
import { getTagsThunk } from "./tags.slice.thunks";
import { ITag } from "./tags.slice.types";

const name = "tags";

export const tagsSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleTagsDrawerAction(state) {
      state.isTagsDrawerOpen = !state.isTagsDrawerOpen
    },
    toggleTagsAddDialog(state) {
      state.showTagsAddDialog = !state.showTagsAddDialog
    },
    setMonthAction(state, action: PayloadAction<number>) {
      state.month = action.payload;
    },
    setYearAction(state, action: PayloadAction<number>) {
      state.year = action.payload;
    },
    setTagAction(state, action: PayloadAction<string>) {
      state.tags = state.tags.map((t: ITag) => {
        if (t.tag === action.payload) {
          t.selected = !t.selected;
        }

        return t;
      });
    },
    resetTagsAction(state) {
      state.month = initialState.month;
      state.year = initialState.year;
      state.tags = state.tags.map(t => ({...t, selected: false }))
      state.categories = initialState.categories
    },
    setCategoryAction(state, action: PayloadAction<TimelineTypeValues>) {
      if (state.categories.showArt && state.categories.showPhotos && state.categories.showVideos) {
        switch (action.payload) {
          case TimelineTypeValues.Art: 
            state.categories.showPhotos = false;
            state.categories.showVideos = false;
            break;
          case TimelineTypeValues.Photo:
            state.categories.showArt = false;
            state.categories.showVideos = false;
            break;
          case TimelineTypeValues.Video:
            state.categories.showArt = false;
            state.categories.showPhotos = false;
            break;
          default: throw Error('unhandled on switch')
        }
      } else {
        switch (action.payload) {
          case TimelineTypeValues.Art: 
          state.categories.showArt = !state.categories.showArt;
          break;
        case TimelineTypeValues.Photo:
          state.categories.showPhotos = !state.categories.showPhotos;

          break;
        case TimelineTypeValues.Video:
          state.categories.showVideos = !state.categories.showVideos;

          break;
        default: throw Error('unhandled on switch')
        }
      }
    }
  },
  extraReducers: {
    [getTagsThunk.fulfilled.type](
      state: ITagState,
      action: PayloadAction<string[]>
    ) {
      state.tags = action.payload.map((p) => ({ tag: p, selected: false }));
    },
  },
});

export const { setMonthAction, setYearAction, resetTagsAction, setTagAction, toggleTagsDrawerAction, setCategoryAction, toggleTagsAddDialog } = tagsSlice.actions;

export default tagsSlice.reducer;
