import { Chip, Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useAppDispatch } from "../../common.app.hooks";
import { setTagAction } from "../tags.slice";
import { ITag } from "../tags.slice.types";

interface IProps {
  color: string
}

const TagsDynamic = (props: IProps) => {
  const dispatch = useAppDispatch();
  
  const sx = { m: 1 };

  const tags = useSelector<RootState, ITag[]>((state) => state.tags.tags);

  const tagRender = tags.map((tag, i) => (
    <Box sx={sx} key={i}>
      <Chip
        clickable={true}
        onClick={() => dispatch(setTagAction(tag.tag))}
        sx={{
          background: tag.selected ? props.color : "grey",
          color: "white",
        }}
        label={tag.tag}
      />
    </Box>
  ));

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-evenly"
      alignItems="center"
      alignContent="center"
    >
      {tagRender}
    </Box>
  );
};

export default TagsDynamic;
