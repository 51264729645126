import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Unauthenticated from '../account/Unauthenticated';

const UnauthenticatedRouter = () => (
        <Routes>
        <Route path="/" element={<Unauthenticated />} />
      </Routes>
    )

export default UnauthenticatedRouter;