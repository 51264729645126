import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardHeader } from "@mui/material";
import TimelineCardMedia from "./TimelineCardMedia";

import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../common.app.hooks";
import { getAvatarForType } from "../../common.avatar";
import clientRoutes from "../../common.routes";
import { getTypeText } from "../timeline.translations";
import { getMediaThunk } from "../timeline.slice.thunks";
import { ITimelineSummary } from "../timeline.slice.types";
import { TimelineTypeValues } from "../timeline.slice.enum";
import TimelineCardVideo from "./TimelineCardVideo";

interface IProps {
  readonly timelineSummary: ITimelineSummary;
}

export const TimelineCard = (props: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getSummary = () => {
    const summary = (kind: string) =>
      props.timelineSummary.numberOfImages.toString() +
      " " +
      (props.timelineSummary.numberOfImages === 1 ? kind : `${kind}s`);

    switch (props.timelineSummary.type) {
      case TimelineTypeValues.Art:
      case TimelineTypeValues.Photo:
        return summary("image");
      case TimelineTypeValues.Video:
        return summary("video");
      default:
        throw new Error("No type mapping");
    }
  };

  const handleDetailClick = (timeline: string) => {
    dispatch(getMediaThunk({ timeline, dispatch }));

    if (props.timelineSummary.type === TimelineTypeValues.Video) {
      navigate(`${clientRoutes.video}`);
    } else {
      navigate(`${clientRoutes.view}/${timeline}`);
    }
  };

  const subheader = `From ${props.timelineSummary.dateDescription}`;

  const media =
    props.timelineSummary.type === TimelineTypeValues.Video ? (
      <TimelineCardVideo videoUrl={props.timelineSummary.videoUrl || ""} />
    ) : (
      <TimelineCardMedia thumbnail={props.timelineSummary.thumbnail} />
    );

  return (
    <Card
      sx={{
        maxWidth: 345,
        background:
          "radial-gradient(circle, rgba(236,230,231,1) 0%, rgba(250,251,255,1) 78%)",
      }}
    >
      <CardHeader
        avatar={getAvatarForType(props.timelineSummary.type, "small")}
        title={getTypeText(props.timelineSummary.type)}
        subheader={subheader}
      />
      <CardActionArea
        onClick={() => handleDetailClick(props.timelineSummary.id)}
      >
        {media}
      </CardActionArea>

      <CardActions>
        <Button
          onClick={() => handleDetailClick(props.timelineSummary.id)}
          size="medium"
        >
          <strong>{getSummary()}</strong>
        </Button>
        <Typography variant="caption">
          {"     last updated on " + props.timelineSummary.updated}
        </Typography>
      </CardActions>
    </Card>
  );
};
