/* eslint-disable no-unused-vars */
import React from "react";
import { Box, CardMedia } from "@mui/material";

interface IProps {
  videoUrl: string;
}

const TimelineCardVideo = (props: IProps) => (
  <Box>
    <iframe
      width="345"
      height="345"
      src={props.videoUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </Box>
);

export default TimelineCardVideo;
