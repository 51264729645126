import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React  from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useAppDispatch } from "../../common.app.hooks";
import { setYearAction } from "../tags.slice";

interface IProps {
  color: string
}

const TagYearPicker = (props: IProps) => {
  const dispatch = useAppDispatch();

  const year = useSelector<RootState, number>(
    (state) => state.tags.year
  );

  const color = { color: year > 0 ? props.color : 'black' };

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setYearAction(Number(event.target.value)));
  };

  const years = () =>
    Array.from(
      { length: new Date().getFullYear() - 2014 },
      (_, i) => 2014 + 1 + i
    ).map((y) => (
      <MenuItem key={y} value={y}>
        {y}
      </MenuItem>
    ));

  return (
    <>
      <InputLabel sx={{ color }} id="year-label">
        Year
      </InputLabel>

      <Select
        sx={{ color }}
        labelId="year-label"
        value={year?.toString()}
        label="Year"
        onChange={handleChange}
      >
        <MenuItem value="0">
          <em>All</em>
        </MenuItem>
        {years()}
      </Select>
    </>
  );
};

export default TagYearPicker;
