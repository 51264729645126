import React from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../common.auth.config";
import { Button } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }

    return (
        <div>
            <Button 
                variant='outlined'
                startIcon={<FacebookIcon />}
                onClick={() => handleLogin()}
                color="primary"
            >
                Login with Facebook
            </Button>
        </div>
    )
};