import * as msal from "@azure/msal-browser";
import { msalInstance } from "..";
import { dispatchFromStore } from "../state/store";
import { accountLoadedAction } from "./account/account.slice";
import { loginRequest, msalConfig } from "./common.auth.config";

export const getAuthToken = async (): Promise<string | undefined> => {
  const msalInstance = new msal.PublicClientApplication(msalConfig);

  try {
    const login: msal.SilentRequest = { ...loginRequest };
    const res = await msalInstance.acquireTokenSilent(login);
    return res.accessToken;
  } catch (error) {
    if (error instanceof msal.InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(loginRequest);
    }
  }
};

export const setupAuthEvents = () => {
  msalInstance.addEventCallback((message: msal.EventMessage) => {
    console.log(message.eventType);
    if ([msal.EventType.HANDLE_REDIRECT_END].includes(message.eventType)) {
      if (userIsAuthenticated()) {
        const account = getAccount();
        dispatchFromStore(
          accountLoadedAction({
            isLoggedIn: true,
            name: account?.name,
            emailAddress: account?.username,
            noAuthorization: false,
          })
        );
      }
    }
  });
};

const userIsAuthenticated = () => msalInstance.getAllAccounts().length > 0;

const getAccount = () => {
  setActiveAccount();

  const account = msalInstance.getActiveAccount();
  return account;
};

const setActiveAccount = () => {
  if (!msalInstance.getActiveAccount()) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
};
