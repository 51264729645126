import React, { useEffect, useState } from "react";
import { Box, Divider, Drawer, Grid, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import TagCategories from "./TagCategories";
import TagsActions from "./TagsActions";
import TagsDynamic from "./TagsDynamic";
import TagToggle from "./TagToggle";
import { RootState } from "../../../state/store";
import { useAppDispatch } from "../../common.app.hooks";
import { toggleTagsDrawerAction } from "../tags.slice";
import TagDates from "./TagDates";
import { getRandomColor } from "../../common.text";

const Tags = () => {
  const dispatch = useAppDispatch();

  const [selectedColor, setSelectedColor] = useState<string>()

  useEffect(() => {
    setSelectedColor(getRandomColor())
  });
  
  const isTagsDrawerOpen = useSelector<RootState, boolean>(
    (state) => state.tags.isTagsDrawerOpen
  );

  return (
    <>
      <TagToggle />
      <Drawer
        PaperProps={{
          sx: { width: "350px" },
        }}
        onClose={() => dispatch(toggleTagsDrawerAction())}
        anchor="right"
        open={isTagsDrawerOpen}
      >
        <Stack
          marginTop={3}
          alignItems="center"
          justifyItems="center"
          ml={3}
          mr={3}
          spacing={2}
        >
          <TagsActions />

          <Box width="350px">
            <Divider>
              <Typography variant="subtitle2">CATEGORIES</Typography>{" "}
            </Divider>
            <Grid container justifyContent="center">
              <Box m={2}>
                <TagCategories color={selectedColor || 'white'} />
              </Box>
            </Grid>
          </Box>

          <Box width="350px">
            <Divider>
              <Typography variant="subtitle2">DATE</Typography>
            </Divider>
            <Grid container justifyContent="center">
              <Box m={2}>
                <TagDates color={selectedColor || 'white'} />
              </Box>
            </Grid>
          </Box>

          <Box width="350px">
            <Divider>
              <Typography variant="subtitle2">TAGS</Typography>
            </Divider>
            <Grid container justifyContent="center">
              <Box m={2}>
                <TagsDynamic color={selectedColor || 'white'} />
              </Box>
            </Grid>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};

export default Tags;
