import { IRecipeDirectory, IRecipe } from "./recipe.slice.types";

export interface IRecipeState {
    directory: IRecipeDirectory[];
    recipe: IRecipe | undefined;
    showSelectedRecipe: boolean;
  }

  export const initialState: IRecipeState = {
    directory: [],
    recipe: undefined,
    showSelectedRecipe: false
  };