import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppDispatch } from "../common.app.hooks";
import clientRoutes from "../common.routes";
import RecipeLanding from "../recipe/RecipeLanding";
import { getRecipeDirectoryThunk } from "../recipe/recipe.slice.thunks";
import { getTagsThunk } from "../tags/tags.slice.thunks";
import Timeline from "../timeline/components/Timeline";
import { TimelineDetail } from "../timeline/components/TimelineDetail";
import TimelineDetailSingleImage from "../timeline/components/TimelineDetailSingleImage";
import TimelineDetailVideos from "../timeline/components/TimelineDetailVideos";
import Comments from "../comments/Comments";
import Landing from "../layout/Landing";
import About from "../layout/About";

const AuthenticatedRouter = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTagsThunk());
    dispatch(getRecipeDirectoryThunk());
  }, []);

  return (
    <Routes>
      <Route path={clientRoutes.home} element={<Landing />} />
      <Route path={clientRoutes.media} element={<Timeline />} />
      <Route path={clientRoutes.about} element={<About />} />

      <Route
        path={clientRoutes.view + "/:timeline"}
        element={<TimelineDetail />}
      />
      <Route
        path={clientRoutes.viewSingle}
        element={<TimelineDetailSingleImage />}
      />
      <Route path={clientRoutes.video} element={<TimelineDetailVideos />} />
      <Route path={clientRoutes.recipes} element={<RecipeLanding />} />
      <Route path={clientRoutes.comments} element={<Comments />} />
    </Routes>
  );
};

export default AuthenticatedRouter;
