import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

import { TimelineCard } from "./TimelineCard";
import Navigation from "./TimelineNavigation";
import { useAppDispatch } from "../../common.app.hooks";
import { getTimelineThunk } from "../timeline.slice.thunks";
import { ITimelineSummaryResponse, ITimelineSummary } from "../timeline.slice.types";

export const Timeline = () => {
  const dispatch = useAppDispatch();

  const timeline = useSelector<RootState, ITimelineSummaryResponse>(
    (state) => state.timeline.timelineSummary
  );

  const timelineContents = timeline.timelines.map(
    (timeline: ITimelineSummary, index: number) => (
      <Box
        sx={{
          background:
            "radial-gradient(circle, rgba(226,228,237,1) 0%, rgba(207,202,202,1) 78%)",
        }}
        margin={2}
        key={index}
      >
        <TimelineCard timelineSummary={timeline} />
      </Box>
    )
  );

  const loadFunc = () =>
    dispatch(
      getTimelineThunk({
        page: timeline.nextPage || 1,
        hasFiltersChanged: false
      })
    );

  return (
    <>
      <Navigation showBack={false} showCalendarFilter={true}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadFunc}
          hasMore={timeline.nextPage !== null}
          loader={
            <CircularProgress />
          }
          useWindow={true}
        >
          <Box
            alignContent="center"
            justifyContent="center"
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            {timelineContents}
          </Box>
        </InfiniteScroll>
      </Navigation>
    </>
  );
};

export default Timeline;
