import React from 'react'
import { CardMedia } from "@mui/material"

interface IProps {
    readonly thumbnail: string;
}

export const TimelineCardMedia = (props: IProps) => (
        <CardMedia
        component="img"
        src={props.thumbnail}
        onError={(e: any) => console.log('wtc', e)}
      />
    )

export default TimelineCardMedia;
