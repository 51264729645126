import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import elizabeth from "./static-image/landing.jpg";
import disney from "./static-image/disney.jpg";
import wendyElizabeth from "./static-image/swing.jpg";

import React from "react";
import { useNavigate } from "react-router-dom";
import clientRoutes from "../common.routes";

const welcomeText = "WELCOME!";

const Landing = () => {
  const isDesktop = useMediaQuery("(min-width:800px)");
  const navigate = useNavigate();

  const media = (
    <span
      onClick={() => navigate(clientRoutes.media)}
      style={{ cursor: "pointer", color: "purple" }}
    >
      <strong>photos, videos, and her artwork</strong>
    </span>
  );

  const recipes = (
    <span
      onClick={() => navigate(clientRoutes.recipes)}
      style={{ cursor: "pointer", color: "purple" }}
    >
      <strong>family recipes</strong>
    </span>
  );

  return (
    <Box>
      <Paper
        sx={{
          marginLeft: 2,
          marginRight: 2,
          marginTop: 5,
          background:
            "radial-gradient(circle, rgba(236,230,231,1) 0%, rgba(250,251,255,1) 78%)",
        }}
      >
        <Grid container alignContent="center" justifyContent="center">
          <Box display="flex" flexDirection={isDesktop ? "row" : "column"}>
            <Box m={10}>
              <img
                height="700px"
                style={{ borderRadius: "2%" }}
                src={elizabeth}
              />
            </Box>
            <Box m={10}>
              <Stack justifySelf="center">
                <Divider sx={{ color: "purple" }} />

                <Grid container justifyContent="center">
                  <Typography
                    sx={{ color: "purple", fontFamily: "Bubblegum Sans" }}
                    variant="h1"
                  >
                    {welcomeText}
                  </Typography>
                </Grid>

                <Divider />
                <br />
                <br />
                <Typography sx={{ color: "grey" }} variant="h5">
                  You can view {media} as well as <strong>{recipes}</strong>{" "}
                </Typography>
                <br />

                <Box mt={3} justifyContent="space-evenly" display="flex">
                  <Box mr={2} mb={5}>
                    <img
                      style={{ borderRadius: "10%" }}
                      height="400px"
                      width="300px"
                      src={disney}
                    />
                  </Box>
                  <Box>
                    <img
                      style={{ borderRadius: "10%" }}
                      height="400px"
                      width="300px"
                      src={wendyElizabeth}
                    />
                  </Box>
                </Box>
                <Grid container justifyContent="center">
                  <span
                    onClick={() => navigate(clientRoutes.about)}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      sx={{ cursor: "pointer", color: "purple" }}
                      variant="subtitle2"
                    >
                      More about this website
                    </Typography>
                  </span>
                </Grid>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Landing;
