import React from "react";
import { render } from "react-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./app/common.auth.config";
import App from "./app/layout/App";
import { setupAuthEvents } from "./app/common.auth";

export const msalInstance = new PublicClientApplication(msalConfig);

const app = (
  <React.StrictMode>
    <App pca={msalInstance} />
  </React.StrictMode>
);

export const renderApp = async () =>
  render(app, document.getElementById("root"));

renderApp().then(() => {
  console.log("starting app...");
  setupAuthEvents();
});
