import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { Box, Grid, Stack, useMediaQuery } from "@mui/material";

import TimelineNavigation from "./TimelineNavigation";
import { RootState } from "../../../state/store";
import { IMedia } from "../timeline.slice.types";

const TimelineDetailVideos = () => {
  const { timeline } = useParams();

  const isDesktop = useMediaQuery("(min-width:800px)");

  useEffect(() => {
    if (timeline) {
      window.scrollTo(0, 0);
    }
  }, []);

  const timelineDetails = useSelector<RootState, IMedia[]>(
    (state) => state.timeline.mediaDetail
  );

  return (
    <Box sx={{ width: "100%" }}>
      <TimelineNavigation showBack={true} showCalendarFilter={false}>
        <Box sx={{ width: "90vw", minHeight: 829 }}>
          <Grid container justifyContent="center">
            <Stack>
              {timelineDetails.map((item, index) => (
                <Box margin={"50px"} key={index}>
                  <iframe
                    key={index}
                    width={isDesktop ? "760" : "300"}
                    height={isDesktop ? "715" : "300"}
                    src={item.videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Box>
              ))}
            </Stack>
          </Grid>
        </Box>
      </TimelineNavigation>
    </Box>
  );
};

export default TimelineDetailVideos;
