import * as React from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { RootState } from "../../../state/store";
import { IMedia } from "../timeline.slice.types";
import TimelineNavigation from "./TimelineNavigation";

const TimelineDetailSingleImage = () => {
  const navigate = useNavigate();

  const media = useSelector<RootState, IMedia | undefined>(
    (state) => state.timeline.selectedMediaDetail
  );

  if (!media) {
    return <></>;
  }

  return (
    <TimelineNavigation showBack={true} showCalendarFilter={false}>
      <img
        onClick={() => navigate(-1)}
        src={`${media.image}&w=162&auto=format`}
        alt={media.description}
        style={{
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          display: "block",
          width: "100%",
        }}
      />
    </TimelineNavigation>
  );
};

export default TimelineDetailSingleImage;
