import React from "react";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

export const NoAuth = () => {
  const noAuthorization = useSelector<RootState, boolean>(
    (state) => state.account.noAuthorization
  );
  return (
    <Dialog
      fullScreen={true}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      open={noAuthorization}
    >
      <Box
        sx={{
          background: "#FFF5EE",
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>You are not authorized for this site yet.</Typography>
        </Grid>
      </Box>
    </Dialog>
  );
};
