import { ITag } from "./tags.slice.types";

export interface ITagState {
  year: number;
  month: number;
  tags: ITag[];
  isTagsDrawerOpen: boolean;
  categories: {
    showArt: boolean,
    showPhotos: boolean,
    showVideos: boolean,
  }
  showTagsAddDialog: boolean
}

export const initialState: ITagState = {
  month: 0,
  year: 0,
  tags: [],
  isTagsDrawerOpen: false,
  categories: {
      showArt: true,
      showPhotos: true,
      showVideos: true
  },
  showTagsAddDialog: false
};
