/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  CircularProgress,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Avatar,
  CardHeader,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Table,
  useMediaQuery,
} from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useAppDispatch } from "../common.app.hooks";
import { toggleShowSelectedRecipe } from "./recipe.slice";
import { IRecipe, IRecipeIngredient } from "./recipe.slice.types";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

const Recipe = () => {
  const [zoom, setZoom] = useState<string | undefined>();
  const isDesktop = useMediaQuery("(min-width:800px)");

  const dispatch = useAppDispatch();
  const recipe = useSelector<RootState, IRecipe | undefined>(
    (state) => state.recipes.recipe
  );

  const width = isDesktop ? 900 : 390;

  if (!recipe) {
    return <CircularProgress />;
  }

  if (zoom) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <Box onClick={() => setZoom(undefined)} marginTop={10}>
          <img src={zoom} />
        </Box>
      </Grid>
    );
  }

  const getIngredients = (ingredients: IRecipeIngredient[]) => (
    <Box ml={5}>
      <Table size="small">
        <TableBody>
          {ingredients.map((ingredient, i) => (
            <TableRow key={i}>
              <TableCell>{ingredient.ingredient}</TableCell>
              <TableCell>
                {ingredient.quantity &&
                  ingredient.quantity +
                    (ingredient.uom ? ` (${ingredient.uom})` : "")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );

  const sections = recipe.sections.map((section, i) => (
    <CardContent key={i}>
      <Typography gutterBottom variant="h6" component="div">
        {section.header}
      </Typography>
      <Box width={width}>{getIngredients(section.ingredients)}</Box>
      <Typography gutterBottom variant="h5" component="div">
        {section.directions}
      </Typography>
    </CardContent>
  ));

  const getImages = (images: string[]) =>
    images.map((image, i) => (
      <Box m={2} key={i}>
        <CardMedia
          onClick={() => {
            setZoom(`data:image/jpeg;base64,${image}`);
          }}
          key={i}
          component="img"
          image={`data:image/jpeg;base64,${image}`}
          alt="green iguana"
        />
      </Box>
    ));

  return (
    <Grid container justifyContent="center">
      <Box>
        <Card sx={{ ml: 20, mr: 20, mt: 5, minWidth: width, maxWidth: width }}>
          <CardHeader
            // eslint-disable-next-line capitalized-comments
            // avatar={
            //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            //     R
            //   </Avatar>
            // }
            action={
              <IconButton
                onClick={() => dispatch(toggleShowSelectedRecipe())}
                aria-label="close"
              >
                <CancelPresentationIcon />
              </IconButton>
            }
            title={recipe.description}
            subheader={recipe.from}
          />

          <CardActionArea>{sections}</CardActionArea>

          <CardContent>
            <Typography>{recipe.directions}</Typography>
          </CardContent>

          {getImages(recipe.images)}

          <CardActions>
            <Button onClick={() => dispatch(toggleShowSelectedRecipe())}>
              Close
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Grid>
  );
};

export default Recipe;
