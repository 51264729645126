import * as React from "react";
import { useEffect, useState, useMemo } from "react";

export interface ReactFlipCardProps {
  cardZIndex?: string;
  containerStyle?: {};
  containerClassName?: string;
  isFlipped?: boolean;
  flipSpeedBackToFront?: number;
  flipSpeedFrontToBack?: number;
  children: [React.ReactNode, React.ReactNode];
}

const ReactCardFlip: React.FC<ReactFlipCardProps> = (props) => {
  const {
    cardZIndex,
    containerStyle,
    containerClassName,
    flipSpeedFrontToBack,
    flipSpeedBackToFront,
  } = props;

  const [isFlipped, setFlipped] = useState(props.isFlipped);

  useEffect(() => {
    if (props.isFlipped !== isFlipped) {
      setFlipped(props.isFlipped);
    }
  }, [props.isFlipped]);

  const getContainerClassName = useMemo(() => {
    let className = "react-card-flip";
    if (containerClassName) {
      className += ` ${containerClassName}`;
    }

    return className;
  }, [containerClassName]);

  const getComponent = (key: 0 | 1) => {
    if (props.children.length !== 2) {
      throw new Error(
        "Component ReactCardFlip requires 2 children to function"
      );
    }

    return props.children[key];
  };

  const frontRotateX = `rotateY(${isFlipped ? 180 : 0}deg)`;
  const backRotateX = `rotateY(${isFlipped ? 0 : -180}deg)`;

  const styles: any = {
    back: {
      WebkitBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      height: "100%",
      left: "0",
      position: isFlipped ? "relative" : "absolute",
      top: "0",
      transform: backRotateX,
      transformStyle: "preserve-3d",
      transition: `${flipSpeedFrontToBack}s`,
      width: "100%",
    },
    container: {
      perspective: "1000px",
      zIndex: `${cardZIndex}`,
    },
    flipper: {
      height: "100%",
      position: "relative",
      width: "100%",
    },
    front: {
      WebkitBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      height: "100%",
      left: "0",
      position: isFlipped ? "absolute" : "relative",
      top: "0",
      transform: frontRotateX,
      transformStyle: "preserve-3d",
      transition: `${flipSpeedBackToFront}s`,
      width: "100%",
      zIndex: "2",
    },
  };

  return (
    <div
      className={getContainerClassName}
      style={{ ...styles.container, ...containerStyle }}
    >
      <div className="react-card-flipper" style={styles.flipper}>
        <div className="react-card-front" style={styles.front}>
          {getComponent(0)}
        </div>

        <div className="react-card-back" style={styles.back}>
          {getComponent(1)}
        </div>
      </div>
    </div>
  );
};

ReactCardFlip.defaultProps = {
  cardZIndex: "auto",
  containerStyle: {},
  flipSpeedBackToFront: 0.6,
  flipSpeedFrontToBack: 0.6,
  isFlipped: false,
};

export default ReactCardFlip;
