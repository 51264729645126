import { BrowserRouter } from "react-router-dom";
// Material-UI imports
import { theme } from "../../styles/theme";

import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { Provider } from "react-redux";
import store from "../../state/store";
import { ThemeProvider } from "@mui/material/styles";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../error/Error";
import AppAuthenticated from "./AppAuthenticated";
import AppUnauthenticated from "./AppUnauthenticated";
import { NoAuth } from "../account/NoAuth";

interface IProps {
  readonly pca: IPublicClientApplication;
}

const App = (props: IProps) => (
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={props.pca}>
            <NoAuth />
            <AppAuthenticated />
            <AppUnauthenticated />
          </MsalProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);

export default App;
