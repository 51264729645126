import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import Recipe from "./Recipe";
import RecipeDirectory from "./RecipeDirectory";

const RecipeLanding = () => {
  const showSelectedRecipe = useSelector<RootState, boolean>(
    (state) => state.recipes.showSelectedRecipe
  );

  const component = showSelectedRecipe ? <Recipe /> : <RecipeDirectory />;
  return <>{ component }</> ;
};

export default RecipeLanding;
