import React from "react";
import { Typography } from "@mui/material";
import { blue, green, orange, pink, purple, red } from "@mui/material/colors";

export const getColorFont = (
  text: string,
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | undefined
) =>
  Object.assign([], text).map((t, i) => {
    const marginRight = t === " " ? 2 : 0;
    // eslint-disable-next-line complexity
    const color = (index: number) => {
      switch (index) {
        case 0:
        case 6:
          return "blue";
        case 1:
        case 8:
        case 13:
        case 18:
        case 23:
          return "red";
        case 2:
        case 9:
        case 14:
        case 19:
        case 24:
          return "green";
        case 3:
        case 10:
        case 15:
        case 20:
        case 25:
          return "purple";
        case 5:
        case 12:
        case 16:
        case 21:
          return "orange";

        default:
          return "black";
      }
    };

    return (
      <Typography
        key={i}
        marginRight={marginRight}
        color={color(i)}
        variant={variant}
      >
        {t}
      </Typography>
    );
  });

export const getRandomColor = () => {
  const random = Math.floor(Math.random() * 6);
  switch (random) {
    case 0:
      return red[500];
    case 1:
      return blue[500];
    case 2:
      return purple[500];
    case 3:
      return green[500];
    case 4:
      return pink[500];
    case 5:
      return orange[500];
    default:
      throw new Error("out of range");
  }
};

export const getMonthTextFromMonth = (month: number) => {
  switch (month) {
    case 1:
      return "JAN";
    case 2:
      return "FEB";
    case 3:
      return "MAR";
    case 4:
      return "APR";
    case 5:
      return "MAY";
    case 6:
      return "JUN";
    case 7:
      return "JUL";
    case 8:
      return "AUG";
    case 9:
      return "SEP";
    case 10:
      return "OCT";
    case 11:
      return "NOV";
    case 12:
      return "DEC";
    default:
      throw new Error("out of range");
  }
};