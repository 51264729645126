import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import { Button } from "@mui/material";
import { useAppDispatch } from "../../common.app.hooks";
import { toggleTagsDrawerAction } from "../tags.slice";

const TagToggle = () => {
  const dispatch = useAppDispatch();

  return (
    <Button
      variant="text"
      endIcon={<SearchIcon />}
      onClick={() => dispatch(toggleTagsDrawerAction())}
    >
      Filter
    </Button>
  );
};

export default TagToggle;
