import { Box } from "@mui/material";
import React from "react";
import TagYearPicker from "./TagYearPicker";
import TagMonthPicker from "./TagMonthPicker";

interface IProps {
  color: string
}

const TagDates = (props: IProps) => {
  const sx = { m: 1 };

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space=evenly"
      alignItems="center"
      alignContent="center"
    >
      <Box sx={sx}>
        <TagYearPicker color={props.color} />
      </Box>
      <Box sx={sx}>
        <TagMonthPicker color={props.color} />
      </Box>
    </Box>
  );
};

export default TagDates;
