import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NavigationMenu = () => {
  const navigate = useNavigate();

  const handleNavigate = (link: string) => {
    navigate(`/${link === "HOME" ? "" : link.toLocaleLowerCase()}`);
  };

  return (
    <>
      {["HOME", "MEDIA", "FAM RECIPES", "ABOUT"].map(
        (link, i) => (
          <Button
            onClick={() => handleNavigate(link)}
            key={i}
            variant="text"
            sx={{ marginLeft: 5, marginRight: 5, color: "purple" }}
          >
            {link}
          </Button>
        )
      )}
    </>
  );
};

export default NavigationMenu;
