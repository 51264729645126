import React  from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useAppDispatch } from "../../common.app.hooks";
import { getAvatarForType } from "../../common.avatar";
import { setCategoryAction } from "../tags.slice";
import { TimelineTypeValues } from "../../timeline/timeline.slice.enum";

interface IProps {
  color: string
}

const TagCategories = (props: IProps) => {
  const dispatch = useAppDispatch();
  
  const categories = useSelector<
    RootState,
    { showArt: boolean; showPhotos: boolean; showVideos: boolean }
  >((state) => state.tags.categories);

  const allSelected =
    categories.showArt && categories.showPhotos && categories.showVideos;

  const styles = {
    "&.MuiButton-text": {
      color: "grey",
    },
    "&.MuiButton-contained": {
      background: props.color,
    },
    "&.MuiButton-outlined": {
      color: "black",
    },
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space=evenly"
      alignItems="center"
      alignContent="center"
    >
      <ButtonGroup variant="outlined" aria-label="text button group">
        <Button
          sx={styles}
          onClick={() => dispatch(setCategoryAction(TimelineTypeValues.Photo))}
          variant={
            categories.showPhotos && !allSelected ? "contained" : "outlined"
          }
          startIcon={getAvatarForType(
            TimelineTypeValues.Photo,
            "small",
            categories.showPhotos && !allSelected ? 'white' : 'grey'
          )}
        >
          Photos
        </Button>
        <Button
          sx={styles}
          variant={
            categories.showVideos && !allSelected ? "contained" : "outlined"
          }
          onClick={() => dispatch(setCategoryAction(TimelineTypeValues.Video))}
          startIcon={getAvatarForType(
            TimelineTypeValues.Video,
            "small",
            categories.showVideos && !allSelected ? 'white' : 'grey'
          )}
        >
          Videos
        </Button>
        <Button
          sx={styles}
          variant={
            categories.showArt && !allSelected ? "contained" : "outlined"
          }
          onClick={() => dispatch(setCategoryAction(TimelineTypeValues.Art))}
          startIcon={getAvatarForType(
            TimelineTypeValues.Art,
            "small",
            categories.showArt && !allSelected ? 'white' : 'grey'
          )}
        >
          Art
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default TagCategories;
