import React from "react";

import { Box, Button, Grid, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Tags from "../../tags/components/Tags";

interface IProps {
  readonly showBack: boolean;
  readonly showCalendarFilter: boolean;
  readonly children: any;
}

const TimelineNavigation = (props: IProps) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box marginBottom={1}>
        {/* //radial-gradient(circle, rgba(226,228,237,1) 0%, rgba(125,113,115,1) 78%); */}
        <Box
          width={"100%"}
          sx={{
            background:
              "radial-gradient(circle, rgba(250,251,255,1) 0%, rgba(236,230,231,1) 78%)",
          }}
          color="transparent"
          position="sticky"
        >
          <Toolbar>
            {props.showBack && (
              <Button onClick={() => navigate(-1)} variant="text">
                BACK
              </Button>
            )}
            {props.showCalendarFilter && (
              <>
                <Box sx={{ flexGrow: 1 }} />
                <Tags />
              </>
            )}
          </Toolbar>
        </Box>
      </Box>
      <Grid container justifyContent="center">
        {props.children}
      </Grid>
    </Box>
  );
};

export default TimelineNavigation;
