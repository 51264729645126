const clientRoutes = {
    home: '/',
    view: '/detail',
    viewSingle: '/single',
    video: '/video',
    recipes: '/fam%20recipes',
    comments: '/comments',
    media: '/media',
    about: '/about'
}

export default clientRoutes;