import { TimelineTypeValues } from "./timeline.slice.enum";

export const getTypeText = (type: number): string => {
    switch (type) {
      case TimelineTypeValues.Art:
        return "ART";
      case TimelineTypeValues.Photo:
        return "PHOTOS";
      case TimelineTypeValues.Video:
        return "VIDEOS";
      default:
        throw new Error("No type mapping");
    }
  };