import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";

const getFaq = (question: string, answer: string) => (
  <>
    <Box mt={5}>
      <Typography sx={{ color: "purple" }} variant="h4">
        <strong>{question}</strong>
      </Typography>
    </Box>
    <Box mt={1}>
      <Typography variant="h6">{answer}</Typography>
    </Box>
  </>
);

const About = () => (
  <Grid container justifyContent="center">
    <Box ml={15} mr={15} mt={5}>
      <Stack>
        <Box mt={10}>
          <Typography sx={{ color: "purple" }} variant="h2">
            FAQ
          </Typography>
        </Box>
        <Box>
          <Divider />
        </Box>

        {getFaq(
          "Why did you make this?",
          "I made it for myself and Wendy but mostly for Elizabeth, and I think others in the family will enjoy it.  I wanted to learn a new web dev technology, and I have not been able to do that at work.  It drove me nuts that I had photos everywhere, but no real way to organize them without using social media or some service where I could not store the full resolution originals or own the content and storage.  I also wanted to capture and save Elizabeths art which I love to look at.  Adding videos and family recipes just seemed fun to do, and hopefully handy."
        )}

        {getFaq(
          "Why use Facebook login?",
          " Everybody has it and their technology and authentication tools are very strong. Therefore I authenticate with a Facebook login and authorize access based on the returned unique id from Facebook. This website asks only for profile information (id, name), and does not store any data or allow editing or posting using your account."
        )}

        {getFaq(
          "How do new photos get added?",
          "I wrote a desktop app that I point at a folder (download from our phones) and allows you to pass/publish each one and remembers that you have looked at it and never asks again.  I can also choose the tags for filter later at this time.  Copies of the photos are stored in Azure storage. Recipes and videos are manually entered."
        )}

        {getFaq(
          "How can I find stuff?",
          "The default sort for photos, videos, and art is the date I added it.  They are always grouped by month and year and you can click on that and see all photos for that period. To see how each photo is tagged by category, click the info link on the bottom left hand corner of the image.  Click Filter and select category, date, and/or tag(s). Most photos on our phone store  metadata that includes the location, date, etc and I use that along with simple tags to categorize the media"
        )}

        {getFaq(
          "LULU?",
          "One morning Elizabeth was misbehaving and I said 'you can be such a.. LULU!'  No idea where it came from, but ever since then, I have often called her LULU. Sometimes she loves it, and sometimes it enrages her.  She made LULU Sammy's middle name and it is on her collar."
        )}

        {getFaq(
          "I want to see the code",
          " Ok, that is suprising! Here it is: https://github.com/bradschoen/rezy-lulu-site (frontend)  https://github.com/bradschoen/rezy-lulu-api (backend)"
        )}
      </Stack>
    </Box>
  </Grid>
);

export default About;
