import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useAppDispatch } from "../common.app.hooks";
import { toggleShowSelectedRecipe } from "./recipe.slice";
import { getRecipeThunk } from "./recipe.slice.thunks";
import { IRecipeDirectory } from "./recipe.slice.types";
import LocalDiningIcon from "@mui/icons-material/LocalDining";

const RecipeDirectory = () => {
  const dispatch = useAppDispatch();
  const directory = useSelector<RootState, IRecipeDirectory[]>(
    (state) => state.recipes.directory
  );

  const a = (
    <Box display="flex" flexDirection="column">
      {directory.map((d, i) => (
        <Box
          display="flex"
          key={i}
          mb={5}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(getRecipeThunk(d._id));
            dispatch(toggleShowSelectedRecipe());
          }}
        >
          <Grid container justifyContent="center">
            <Box>
              <Typography sx={{color: 'purple', fontFamily: 'Bubblegum Sans'}} textAlign="center" variant="h6">
                {d.description}
              </Typography>
            </Box>
            <Box ml={1} mr={1}>
              <LocalDiningIcon />
            </Box>
            <Box>
              <Typography sx={{color: 'purple', fontFamily: 'Bubblegum Sans'}} textAlign="center" variant="h6">
                {d.from}
              </Typography>
            </Box>
          </Grid>
        </Box>
      ))}
    </Box>
  );

  return (
    <Box mt={10}>
      <Grid container justifyContent="center">
        {a}
      </Grid>
    </Box>
  );
};

export default RecipeDirectory;
