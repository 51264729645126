import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Box, Dialog, Grid } from "@mui/material";
import { SignInButton } from "./SignInButton";
import eb from "./images/login.png";

export const Login = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Dialog
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      open={!isAuthenticated}
    >
      <Box
        sx={{
          background:
            "#FFF5EE",
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box marginTop={5}>
            <img src={eb} />
          </Box>

          <Box margin={5}>
            <SignInButton />
          </Box>
        </Grid>
      </Box>
    </Dialog>
  );
};
