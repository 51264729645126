import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const name = "account";

interface IAccountState {
  isLoggedIn: boolean;
  emailAddress?: string;
  name?: string;
  noAuthorization: boolean;
}

const initialState: IAccountState = {
  isLoggedIn: false,
  emailAddress: undefined,
  noAuthorization: false
};

export const accountSlice = createSlice({
  name,
  initialState,
  reducers: {
    accountLoadedAction(
      state: IAccountState,
      action: PayloadAction<IAccountState>
    ) {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.emailAddress = action.payload.emailAddress;
      state.name = action.payload.name;
    },
    setNoAuthAction(state: IAccountState) {
      state.noAuthorization = true;
    }
  },
});

export const { accountLoadedAction, setNoAuthAction } = accountSlice.actions;

export default accountSlice.reducer;
