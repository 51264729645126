import { UnauthenticatedTemplate } from "@azure/msal-react";
import { Box } from "@mui/material";
import React from "react";
import UnauthenticatedRouter from "../router/UnauthenticatedRouter";

const AppUnauthenticated = () => (
  <UnauthenticatedTemplate>
    <Box>
      <UnauthenticatedRouter />
    </Box>
  </UnauthenticatedTemplate>
);

export default AppUnauthenticated;
