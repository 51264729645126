import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, IRecipeState } from "./recipe.slice.state";
import { getRecipeDirectoryThunk, getRecipeThunk } from "./recipe.slice.thunks";
import { IRecipe, IRecipeDirectory } from "./recipe.slice.types";

export const name = "recipe";

export const recipeSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleShowSelectedRecipe(state) {
        state.showSelectedRecipe = !state.showSelectedRecipe
      },
  },
  extraReducers: {
    [getRecipeDirectoryThunk.fulfilled.type](
      state: IRecipeState,
      action: PayloadAction<IRecipeDirectory[]>
    ) {
      console.log('slice called')
      state.directory = action.payload
    },
    [getRecipeThunk.fulfilled.type](
        state: IRecipeState,
        action: PayloadAction<IRecipe>
      ) {
        state.recipe = action.payload
      },
  },
});

export const { toggleShowSelectedRecipe
} = recipeSlice.actions;

export default recipeSlice.reducer;
