import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, ITimelineState } from "./timeline.slice.state";
import {
  getTimelineThunk,
  getMediaThunk,
  toggleAppyTagThunk,
} from "./timeline.slice.thunks";
import { IGetTimelineResponse } from "./timeline.slice.thunks.types";
import { IMedia } from "./timeline.slice.types";

const name = "timeline";

export const timelineSlice = createSlice({
  name,
  initialState,
  reducers: {
    setMediaDetail(state, action: PayloadAction<IMedia>) {
      state.selectedMediaDetail = action.payload;
    },
    setMediaDetailLoaded(state, action: PayloadAction<string>) {
      const index = state.mediaDetail.findIndex((f) => f.id === action.payload);
      state.mediaDetail[index].isLoaded = true;
    },
    clearMediaDetail(state) {
      state.mediaDetail = [];
      state.selectedMediaDetail = undefined;
    },
  },
  extraReducers: {
    [getTimelineThunk.fulfilled.type](
      state: ITimelineState,
      action: PayloadAction<IGetTimelineResponse>
    ) {
      if (action.payload.hasFiltersChanged) {
        state.timelineSummary = action.payload.timelineSummary;
      } else {
        state.timelineSummary = {
          ...action.payload.timelineSummary,
          timelines: state.timelineSummary.timelines.concat(
            action.payload.timelineSummary.timelines
          ),
        };
      }
    },
    [getMediaThunk.fulfilled.type](
      state: ITimelineState,
      action: PayloadAction<IMedia[]>
    ) {
      state.mediaDetail = action.payload;
    },
    [toggleAppyTagThunk.fulfilled.type](
      state: ITimelineState,
      action: PayloadAction<IMedia>
    ) {
      state.mediaDetail = state.mediaDetail.map((md) => {
        if (md.id === action.payload.id) {

          return action.payload;
        }

        return md;
      });
    },
  },
});

export const {
  setMediaDetail,
  clearMediaDetail,
  setMediaDetailLoaded,
} = timelineSlice.actions;

export default timelineSlice.reducer;
