import { Grid, Box } from "@mui/material";
import React from "react";

const Comments = () => (
  <Grid container justifyContent="center" >
    <Box m={20}>
      <div
        className="fb-comments"
        data-href="https://elizabethschoen.com/comments"
        data-width="500"
        data-numposts="50"
      ></div>
    </Box>
  </Grid>
);

export default Comments;
