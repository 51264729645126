import { Box } from "@mui/material";
import React from "react";
import { Login } from "./Login";
import "./unauthenticated.css";

const Unauthenticated = () => (
  <Box className="luludotshead">
    <Login />
    <Box className="luludotsbody"></Box>bbb
  </Box>
);

export default Unauthenticated;
