import { createAsyncThunk } from "@reduxjs/toolkit";
import { dispatchFromStore, getState } from "../../state/store";
import { setNoAuthAction } from "../account/account.slice";
import { getAuthToken } from "../common.auth";
import httpCommon from "../common.http";
import { clearMediaDetail } from "./timeline.slice";
import {
  IGetTimelineRequest,
  IGetTimelineResponse,
  IGetMediaThunkRequest,
  IGetMediaImageRequest,
  IMediaImage,
  IMediaTagApplyRequest,
} from "./timeline.slice.thunks.types";
import { IMedia, ITimelineSummaryResponse } from "./timeline.slice.types";

export const getTimelineThunk = createAsyncThunk(
  `${name}/defaultTimeline`,
  async (request: IGetTimelineRequest): Promise<IGetTimelineResponse> => {
    const token = await getAuthToken();
    const state = getState();

    const res = await httpCommon.get<ITimelineSummaryResponse>(
      `/timeline?page=${request.page}&year=${state.tags.year}&month=${state.tags.month}&showPhotos=${state.tags.categories.showPhotos}&showArt=${state.tags.categories.showArt}&showVideos=${state.tags.categories.showVideos}&tags=${state.tags.tags.filter(f => f.selected).map(m => m.tag)}`,
      {
        headers: { Authorization: `bearer ${token}` },
      }
    );

    if (res.status === 403) {
      dispatchFromStore(setNoAuthAction());
    }
    
    return {
      timelineSummary: res.data,
      hasFiltersChanged: request.hasFiltersChanged,
    };
  }
);

export const getMediaThunk = createAsyncThunk(
  `${name}/timelineDetail`,
  async (request: IGetMediaThunkRequest): Promise<IMedia[]> => {
    const token = await getAuthToken();
    const state = getState();

    const res = await httpCommon.get<IMedia[]>(
      `/media/timeline/${request.timeline}?tags=${state.tags.tags.filter(f => f.selected).map(m => m.tag)}`,
      {
        headers: { Authorization: `bearer ${token}` },
      }
    );

    request.dispatch(clearMediaDetail());

    return res.data;
  }
);

export const getMediaImageThunk = createAsyncThunk(
  `${name}/timelineDetailImage`,
  async (request: IGetMediaImageRequest): Promise<IMediaImage> => {
    const token = await getAuthToken();

    const res = await httpCommon.get<string>(
      `/media/image?container=${request.container}&blob=${request.blob}`,
      {
        headers: { Authorization: `bearer ${token}` },
      }
    );
    return { dataUrl: res.data, id: request.id };
  }
);

export const toggleAppyTagThunk = createAsyncThunk(
  `${name}/apply-tag`,
  async (mediaTagApplyRequest: IMediaTagApplyRequest): Promise<IMedia> => {
    const token = await getAuthToken();
    const res = await httpCommon.post<IMedia>(
      "/media/tags",
      { ...mediaTagApplyRequest },
      {
        headers: { Authorization: `bearer ${token}` },
      }
    );

    return res.data;
  }
);
