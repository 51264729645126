import React from "react";
import WelcomeName from "../account/WelcomeName";

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import NavigationMenu from "./NavigationMenu";
import NavigationMenuMobile from "./NavigationMenuMobile";

const NavBar = () => {
  const isDesktop = useMediaQuery("(min-width:800px)");

  const fontSize = isDesktop ? "h6" : "subtitle2";

  return (
    <Box
      sx={{
        background:
          "radial-gradient(circle, rgba(236,230,231,1) 0%, rgba(250,251,255,1) 78%)",
      }}
    >
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item xs>
          <Typography sx={{color: 'purple'}} marginLeft={3} variant={fontSize}>
            EB
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="center">
          
            <Typography variant="h3" sx={{color: 'purple', fontFamily: 'Bubblegum Sans'}}>LULU</Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box display="flex" marginRight={2} justifyContent="right">
            <WelcomeName />
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        sx={{ background: "linear-gradient(#F6F6F9, #FDFCFC);" }}
        justifyContent="center"
        alignItems="center"
      >
        {isDesktop ? <NavigationMenu /> : <NavigationMenuMobile />}
      </Box>
    </Box>
  );
};

export default NavBar;
