import React, { useState } from "react";
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ReactCardFlip from "./TimelineDetailImageFlip";
import { useAppDispatch } from "../../common.app.hooks";
import { getRandomColor, getMonthTextFromMonth } from "../../common.text";
import { setMediaDetailLoaded } from "../timeline.slice";
import { IMedia } from "../timeline.slice.types";
import TagsAdd from "../../tags/components/TagsAdd";

interface IProps {
  readonly key: number;
  readonly media: IMedia;
  readonly onImageClick: Function;
}

const TimelineDetailImage = (props: IProps) => {
  const [flipped, setFlipped] = useState(false);

  const dispatch = useAppDispatch();

  const color = getRandomColor();

  const getLocation = () => {
    const { city, state } = props.media;
    return city && state ? `${city}, ${state}` : undefined;
  };

  const getDate = () => {
    const { year, month, day } = props.media;
    if (day && month && year) {
      return `${month}.${day}.${year}`;
    }

    if (month && year) {
      return `${getMonthTextFromMonth(month)} ${year}`;
    }

    if (year) {
      return year.toString();
    }

    return undefined;
  };

  // eslint-disable-next-line capitalized-comments
  // const download = (
  //   <Box display="flex">
  //     <IconButton
  //       size="small"
  //       aria-label="download"
  //       onClick={() => handleClick()}
  //     >
  //       <CloudDownloadIcon sx={{ color }} />
  //     </IconButton>
  //   </Box>
  // );

  const description = (
    <Typography variant="caption" sx={{ color }}>
      {props.media.description}
    </Typography>
  );

  // eslint-disable-next-line capitalized-comments
  // const handleClick = () => {
  //   window.open(props.media.image, "_blank");
  // };

  const getImage = (isFlip: boolean) => (
    <img
      onLoad={() => {
        dispatch(setMediaDetailLoaded(props.media.id));
      }}
      onClick={() => !isFlip && props.onImageClick(props.media)}
      src={`${props.media.image}&w=162&auto=format`}
      srcSet={`${props.media.image}&w=162&auto=format&dpr=2 2x`}
      alt={props.media.description}
      style={{
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        display: "block",
        width: "100%",
        opacity: isFlip ? 0.2 : 1,
      }}
    />
  );

  const chips = [getLocation() || "Location N/A", getDate() || "Date N/A"]
    .concat(props.media.tags)
    .map((m, i) => (
      <Box key={i} m={1}>
        <Chip
          size="small"
          clickable={false}
          key={i}
          label={m}
          sx={{
            background: getRandomColor(),
            color: "white",
          }}
        />
      </Box>
    ));

  return (
    <>
      <ReactCardFlip isFlipped={flipped}>
        <Box flexDirection="column" display="flex">
          {getImage(flipped)}
          <Box sx={{ background: color, height: "3px" }}></Box>

          <Box
            style={{
              position: "absolute",
              bottom: "2%",
              left: "5%",
              transform: "translateX(-50%)",
            }}
          >
            <Box display="flex" sx={{ background: "transparent" }}>
              <IconButton
                aria-label="delete"
                onClick={() => setFlipped(!flipped)}
              >
                <InfoIcon sx={{ color }} />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box
          onClick={() => setFlipped(!flipped)}
          flexDirection="column"
          display="flex"
        >
          {getImage(flipped)}

          <Box sx={{ background: color, height: "3px" }}></Box>

          <Box
            style={{
              position: "absolute",
              width: "90%",
              top: "40%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{ opacity: 0.8, background: "rgba(236,230,231,1)" }}
            >
              <Box padding={"1px"}>{description}</Box>

              <Grid container justifyContent="center">
                {chips}
              </Grid>
              {/* <Button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(toggleTagsAddDialog());
                }}
              >
                Add Tags
              </Button> */}
            </Box>
          </Box>
        </Box>
      </ReactCardFlip>
      <TagsAdd id={props.media.id} />
    </>
  );
};

export default TimelineDetailImage;
