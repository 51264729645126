import React from "react";
import { Box, Button, Divider, Grid } from "@mui/material";
import { useAppDispatch } from "../../common.app.hooks";
import { resetTagsAction, toggleTagsDrawerAction } from "../tags.slice";
import { getTimelineThunk } from "../../timeline/timeline.slice.thunks";

const TagsActions = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Grid container justifyContent="right" direction="row">
        <Grid item>
          <Box>
            <Button
              onClick={() => dispatch(resetTagsAction())}
              variant="outlined"
            >
              Reset
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Box ml={2}>
            <Button
              onClick={() => {
                dispatch(toggleTagsDrawerAction());
                dispatch(getTimelineThunk({page: 1, hasFiltersChanged: true}))
              }}
              variant="outlined"
            >
              Ok
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mb={7}>
        <Divider />
      </Box>
    </>
  );
};

export default TagsActions;
