import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { Backdrop, Box, CircularProgress, useMediaQuery } from "@mui/material";

import { Masonry } from "@mui/lab";
import TimelineNavigation from "./TimelineNavigation";
import TimelineDetailImage from "./TimelineDetailImage";
import { RootState } from "../../../state/store";
import { useAppDispatch } from "../../common.app.hooks";
import clientRoutes from "../../common.routes";
import { setMediaDetail } from "../timeline.slice";
import { IMedia } from "../timeline.slice.types";

export const TimelineDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { timeline } = useParams();

  const isDesktop = useMediaQuery("(min-width:800px)");

  useEffect(() => {
    if (timeline) {
      window.scrollTo(0, 0);
    } 
  }, []);

  const timelineDetails = useSelector<RootState, IMedia[]>(
    (state) => state.timeline.mediaDetail
  );

  const allLoaded = timelineDetails.filter((f) => !f.isLoaded).length === 0;
  const visibility = allLoaded ? "visible" : "hidden";

  const onImageClick = (media: IMedia) => {
    dispatch(setMediaDetail(media));
    navigate(clientRoutes.viewSingle);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TimelineNavigation showBack={true} showCalendarFilter={false}>
        <Box sx={{ width: "90vw", minHeight: 829 }}>
          <Box sx={{ visibility }}>
            <Masonry columns={isDesktop ? 3 : 1} spacing={2}>
              {timelineDetails.map((item, index) => (
                <TimelineDetailImage
                  key={index}
                  media={item}
                  onImageClick={onImageClick}
                />
              ))}
            </Masonry>
          </Box>
          <Backdrop open={!allLoaded}>
            <Box>
              <CircularProgress />
            </Box>
          </Backdrop>
        </Box>
      </TimelineNavigation>
    </Box>
  );
};
