/* eslint-disable no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { dispatchFromStore } from "../../state/store";
import { setNoAuthAction } from "../account/account.slice";
import { getAuthToken } from "../common.auth";
import httpCommon from "../common.http";
import { ITagResponse } from "./tags.slice.types";

export const getTagsThunk = createAsyncThunk(
  `${name}/get-tags`,
  async (): Promise<string[]> => {
    const token = await getAuthToken();

    try {
      const res = await httpCommon.get<ITagResponse[]>("/tags", {
        headers: { Authorization: `bearer ${token}` },
      });

      console.log("resss", res);

      return res.data.map((m) => m.category);
    } catch (e) {
      const err = e as AxiosError;
      if (err.response) {
        if (err.response.status === 403) {
          dispatchFromStore(setNoAuthAction());
        }
      }

      return [];
    }
  }
);
