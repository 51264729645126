import React from "react";
import NavBar from "./NavBar";
import { Box } from "@mui/material";

interface IProps {
  readonly children: any;
}

export const PageLayout = (props: IProps) => (
    <Box>
      <NavBar />
      {props.children}
    </Box>
  );
